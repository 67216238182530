import React from "react";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import { useHistory } from "react-router-dom";
import { isValidUsername } from "./services/usernameValidator";
import { isValidPassword, getPasswordInvalidText } from "./services/passwordValidator";
import Paper from '@material-ui/core/Paper';
import { canResetPasswordOfThisUsernameAsync } from './services/resetPasswordService'
import { signUpAsync } from "./services/signUpService";
import PasswordTextField from "./PasswordTextField";

export default function ResetPasswordPage() {
    const history = useHistory()
    const [username, setUsername] = React.useState('')
    const [usernameError, setUsernameError] = React.useState(false)

    const [password, setPassword] = React.useState('')
    const [passwordError, setPasswordError] = React.useState(false)

    const [failed, setFailed] = React.useState(false)
    const [isStep2, setIsStep2] = React.useState(false)

    const _handleFormSubmit = (event) => {
        event.preventDefault()

        if (!isValidPassword(password)) {
            setPasswordError(true)
            return
        }

        (async () => {
            await signUpAsync(username, password)
            history.push('/')
        })()

    }

    const _handleUsernameChanged = (event) => {
        setUsernameError(false)
        setFailed(false)
        const newValue = event.target.value
        setUsername(newValue)
    }

    const _handlePasswordChanged = (event) => {
        setPasswordError(false)
        setFailed(false)
        const newValue = event.target.value
        setPassword(newValue)
    }

    const _handleOnSendButtonClicked = () => {
        if (!isValidUsername(username)) {
            setUsernameError(true)
            return
        }

        (async () => {
            if (!await canResetPasswordOfThisUsernameAsync(username)) {
                setFailed(true)
                setUsername('')
                return
            }
            setIsStep2(true)
        })()
    }

    return (
        <React.Fragment>
            <div className="page-title-with-subtitle">
                <Typography variant="h4" style={{marginBottom: '18px'}}>Wachtwoord vergeten</Typography>
                {isStep2 ? null :
                    <Typography>Weet je het wachtwoord niet meer? Vul hieronder je gebruikersnaam in.</Typography>
                }
            </div>
            <div className="page-content">

                {!failed ? null :
                    <Paper style={{ padding: '10px', backgroundColor: '#ffecec' }}>
                        <Typography variant="caption">Er is geen account gevonden met dit gebruikersnaam.</Typography>
                    </Paper>
                }
                <div>
                    <form noValidate style={{ display: "block" }} onSubmit={_handleFormSubmit}>
                        {isStep2 ? null :
                            <TextField
                                error={usernameError}
                                helperText={usernameError ? "Het ingevulde gebruikersnaam is niet correct." : ""}
                                margin="normal"
                                fullWidth
                                required
                                id="username"
                                label="Gebruikersnaam"
                                name="username"
                                autoComplete="username"
                                autoFocus
                                onChange={_handleUsernameChanged}
                                value={username}
                            />
                        }
                        {!isStep2 ? null :
                            <PasswordTextField
                                passwordError={passwordError}
                                onChangeCallback={_handlePasswordChanged}
                                password={password}
                                // intentionally make this 'current' instead of 'new' because else
                                // the autofill wouldn't work.
                                autoComplete="current-password" 
                                passwordErrorMessage={getPasswordInvalidText()}
                            />
                        }
                        {isStep2 ? null :
                            <Button
                                fullWidth
                                variant="contained"
                                color="primary"
                                style={{ marginTop: "18px", marginBottom: "18px" }}
                                onClick={_handleOnSendButtonClicked}
                            >
                                Verzenden
                            </Button>
                        }
                        {!isStep2 ? null :
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                style={{ marginTop: "20px", marginBottom: "10px" }}
                            >
                                Reset wachtwoord
                            </Button>
                        }
                    </form>
                </div>
            </div>
        </React.Fragment>
    );
}
