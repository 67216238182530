import { hashAsync } from "./hashService"
import { get } from "./storageService"

const canResetPasswordOfThisUsernameAsync = async(username) => {
    const normalizedUsername = username.toLowerCase()
    const hashedUsername = await hashAsync(normalizedUsername)
    const hashedPassword = get(hashedUsername)

    return !!hashedPassword
}

export {canResetPasswordOfThisUsernameAsync}