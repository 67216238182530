import React from 'react'
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import FormHelperText from '@material-ui/core/FormHelperText';

export default function PasswordTextField({
    passwordError, 
    onChangeCallback, 
    password,
    label="Wachtwoord",
    passwordErrorMessage="Het wachtwoord mag niet leeg zijn",
    autoComplete
}) {
    const [showPassword, setShowPassword] = React.useState(false)

    const handleClickShowPassword = () => {
        setShowPassword(!showPassword)
    }

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    }
    return (
        <FormControl 
            margin="normal" 
            fullWidth 
            required
            name="password"
            id="password"
            autoComplete={autoComplete}
            error={passwordError}
        >
            <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
            <Input
                id="outlined-adornment-password"
                autoComplete={autoComplete}
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={onChangeCallback}
                aria-describedby="component-error-text"
                endAdornment={
                <InputAdornment position="end">
                    <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    >
                    {!showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                </InputAdornment>
                }
                labelWidth={label.length * 10}
            />
            <FormHelperText id="component-error-text">{passwordError ? passwordErrorMessage: ""}</FormHelperText>
        </FormControl>
    )
}