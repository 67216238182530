import React from 'react';
import { config } from './config.js'
import CustomCard from './CustomCard.js';

export default function CardPanel() {

  const getCards = () => {
    return config.cards.map(card => <CustomCard
      imageSrc={card.imgSrc}
      title={card.title}
      subtitle={card.subtitle}
    />)
  }

  return (
    <div style={{marginTop: '36px'}}>
        {getCards()}
    </div>
  );
}
