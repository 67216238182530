import React from "react";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Button from "@material-ui/core/Button";
import CardPanel from './CardPanel'
import { signUpAsync } from "./services/signUpService";
import { useHistory } from 'react-router-dom';
import { isValidPassword, getPasswordInvalidText } from "./services/passwordValidator";
import { signInAsync } from "./services/signInService";

import Link from '@material-ui/core/Link';
import PasswordTextField from "./PasswordTextField";

export default function AccountPage({
    isAuthenticated, setIsAuthenticated, authenticatedUsername
}) {
    const [password1, setPassword1] = React.useState('')
    const [password1Error, setPassword1Error] = React.useState(false)
    const [password2, setPassword2] = React.useState('')
    const [password2Error, setPassword2Error] = React.useState(false)
    const [changePasswordStep, setChangePasswordStep] = React.useState(false)
    const [failed, setFailed] = React.useState(false)
    const history = useHistory()

    if (!isAuthenticated) {
        history.push('/')
    }

    const _handleFormSubmit = (event) => {
        event.preventDefault()
    }

    const _handleConfirmChangePasswordClicked = () => {
        if (!isValidPassword(password1)) {
            setPassword1Error(true)
            return
        }

        if (!isValidPassword(password2)) {
            setPassword2Error(true)
            return
        }

        (async () => {
            if (!await signInAsync(authenticatedUsername, password1)) {
                setFailed(true)
                return
            }
            await signUpAsync(authenticatedUsername, password2)
            setChangePasswordStep(false)
        })()
    }

    const _handleCancelPasswordClicked = () => {
        setPassword1('')
        setPassword2('')
        setPassword1Error(false)
        setPassword2Error(false)
        setChangePasswordStep(false)
        setFailed(false)
    }

    const _handleChangePasswordClicked = () => {
        setChangePasswordStep(true)
    }

    const _handlePassword1Changed = (event) => {
        setFailed(false)
        setPassword1Error(false)
        const newValue = event.target.value
        setPassword1(newValue)
    }

    const _handlePassword2Changed = (event) => {
        setFailed(false)
        setPassword2Error(false)
        const newValue = event.target.value
        setPassword2(newValue)
    }

    const _handleSignOutClicked = () => {
        setPassword1('')
        setPassword2('')
        setPassword1Error(false)
        setPassword2Error(false)
        setChangePasswordStep(false)
        setIsAuthenticated(false)
    }

    return (
        <React.Fragment>
            <div style={{ display: 'flex', justifyContent: 'space-between', width: '66%'}}>
                {!changePasswordStep ?
                    <div style={{ width: '66%', textAlign: 'left' }}>
                        <Typography variant="h4">Welkom</Typography>
                        <Typography variant="h6">{authenticatedUsername}</Typography>
                    </div> : 
                    <div style={{ width: '66%', textAlign: 'left' }}>
                        <Typography variant="h4">Wachtwoord wijzigen</Typography>
                    </div>
                }          
            </div>
            
            {!failed ? null :
                <Paper style={{ padding: '10px', backgroundColor: '#ffecec' }}>
                    <Typography variant="caption">Je hebt een incorrect huidig wachtwoord ingevoerd.</Typography>
                </Paper>
            }

            {changePasswordStep ?
                <div style={{width: '66%'}}>
                    <form noValidate style={{ display: "block" }} onSubmit={_handleFormSubmit}>
                        <PasswordTextField
                            passwordError={password1Error}
                            onChangeCallback={_handlePassword1Changed}
                            password={password1}
                            label="Huidig wachtwoord"
                            // intentionally make this 'new' instead of 'current' because else
                            // the autofill will only autofill the current, never the new.
                            // make both new - new allows the user to autofill each field separately
                            autoComplete="new-password"
                            passwordErrorMessage={getPasswordInvalidText()}
                        />
                        <PasswordTextField
                            passwordError={password2Error}
                            onChangeCallback={_handlePassword2Changed}
                            password={password2}
                            label="Nieuw wachtwoord"
                            autoComplete="new-password"
                            passwordErrorMessage={getPasswordInvalidText()}
                        />

                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            style={{ marginTop: "18px", marginBottom: "18px" }}
                            onClick={_handleConfirmChangePasswordClicked}
                        >
                            Aanpassen
                        </Button>
                        <Button
                            fullWidth
                            variant="contained"
                            style={{ marginTop: "18px", marginBottom: "18px" }}
                            onClick={_handleCancelPasswordClicked}
                        >
                            Annuleren
                        </Button>

                    </form>
                </div> : 
                <div style={{display: 'block', textAlign: 'left', width: '66%'}}>
                    <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{ marginTop: "36px", marginBottom: "18px" }}
                        onClick={_handleSignOutClicked}
                    >
                        UITLOGGEN
                    </Button>
                    <Link href="#" onClick={_handleChangePasswordClicked}>
                        <u>{"Wachtwoord wijzigen"}</u>
                    </Link>
                    <CardPanel />
                </div>
                
                
            }
        </React.Fragment>
    );
}
