import { hashAsync } from "./hashService"
import { set } from './storageService'

const signUpAsync = async(username, password) => {
    const normalizedUsername = username.toLowerCase()
    const hashedUsername = await hashAsync(normalizedUsername)
    const hashedPassword = await hashAsync(password)

    set(hashedUsername, hashedPassword)
}

export { signUpAsync }