const config = {
    brand: {
        name: ['Het loket','voor de','Omgevingsvergunning'],
        imgSrc: './frontpage.jpg'
    }, 
    account: {
        imgSrc: './accountpage.jpg'
    },
    cards: [
        {
            imgSrc: './news.jpg',
            title: 'Aanvraag/melding',
            subtitle: 'Een vergunning aanvragen of melding doen begint hier.'
        }
    ]
}

export { config }