import { get } from "./storageService"
import { hashAsync } from "./hashService"

const signInAsync = async(username, password) => {
    const normalizedUsername = username.toLowerCase()
    const hashedUsername = await hashAsync(normalizedUsername)
    const hashedPassword = await hashAsync(password)

    const storedPassword = get(hashedUsername)
    return hashedPassword === storedPassword
}

export { signInAsync }