import React from "react"
import { config } from './config.js'

export default function Brand() {
    const lines = config.brand.name
    return (
        <div className="page-brand line-height header-text">
            <b>
            <span>{lines[0]}</span><br/>
            <span>{lines[1]}</span><br/>
            <span>{lines[2]}</span>
            <span style={{fontSize: '2rem', lineHeight: '1'}}><sup>®</sup></span>
            </b>
        </div>
    )
}