const isValidPassword_shop1 = (password) => {
    return password.length > 0
}

const getPasswordInvalidText_shop1 = () => {
    return 'Het wachtwoord mag niet leeg zijn.'
}

const isValidPassword_shop4 = (password) => {
    return password.length >= 6 && password.length <=15
}

const getPasswordInvalidText_shop4 = () => {
    return 'Het wachtwoord moet minimaal 6 en maximaal 15 tekens bevatten.'
}

export { 
    isValidPassword_shop1 as isValidPassword,
    getPasswordInvalidText_shop1 as getPasswordInvalidText
}