import React from "react";
import Avatar from "@material-ui/core/Avatar";
import CreateIcon from '@material-ui/icons/Create';
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

import { signUpAsync } from "./services/signUpService";
import { isValidUsername } from "./services/usernameValidator";
import { useHistory } from 'react-router-dom';
import { isValidPassword, getPasswordInvalidText } from "./services/passwordValidator";
import PasswordTextField from "./PasswordTextField";

export default function SignUpPage() {
    const [password1, setPassword1] = React.useState('')
    const [password1Error, setPassword1Error] = React.useState(false)
    const [password2, setPassword2] = React.useState('')
    const [password2Error, setPassword2Error] = React.useState(false)
    const [username, setUsername] = React.useState('')
    const [usernameError, setUsernameError] = React.useState(false)
    const history = useHistory()

    const _handleFormSubmit = (event) => {
        event.preventDefault()

        if (!isValidUsername(username)) {
            setUsernameError(true)
            return
        }

        if (!isValidPassword(password1)) {
            setPassword1Error(true)
            return
        }

        if (password1 !== password2) {
            setPassword2Error(true)
            return
        }

        (async() => {
            await signUpAsync(username, password1)
            history.push('/')
        })()
    }

    const _handleUsernameChanged = (event) => {
        setUsernameError(false)
        const newValue = event.target.value
        setUsername(newValue)
    }

    const _handlePassword1Changed = (event) => {
        setPassword1Error(false)
        const newValue = event.target.value
        setPassword1(newValue)
    }

    const _handlePassword2Changed = (event) => {
        setPassword2Error(false)
        const newValue = event.target.value
        setPassword2(newValue)
    }

    return (
        <React.Fragment>
            <div className="page-title">
                <Avatar style={{ marginRight: "20px" }} variant="rounded">
                    <CreateIcon />
                </Avatar>
                <Typography variant="h4" style={{textAlign:"left"}}>Maak een account aan</Typography>
            </div>
            <div className="page-content">
                <form noValidate style={{ display: "block" }} onSubmit={_handleFormSubmit}>
                    <TextField
                        error={usernameError}
                        helperText={usernameError ? "Het ingevulde gebruikersnaam is niet correct.": ""}
                        margin="normal"
                        fullWidth
                        required
                        id="username"
                        label="Gebruikersnaam"
                        name="username"
                        autoComplete="username"
                        autoFocus
                        onChange={_handleUsernameChanged}
                    />
                    <PasswordTextField
                        passwordError={password1Error}
                        onChangeCallback={_handlePassword1Changed}
                        password={password1}
                        autoComplete="new-password"
                        passwordErrorMessage={getPasswordInvalidText()}
                    />
                    <PasswordTextField
                        passwordError={password2Error}
                        onChangeCallback={_handlePassword2Changed}
                        password={password2}
                        label="Wachtwoord bevestiging"
                        passwordErrorMessage="De wachtwoorden moeten hetzelfde zijn"
                        autoComplete="new-password"
                    />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        style={{ marginTop: "20px", marginBottom: "10px" }}
                    >
                        Maak een account aan
                    </Button>
                    
                </form>
            </div>
        </React.Fragment>
    );
}
