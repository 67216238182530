import React from 'react'
import LoginForm from "./LoginForm";
import "./styles.css";
import { BrowserRouter as Router, Switch, Route, } from "react-router-dom";
import SignUpPage from "./SignUpPage";
import ResetPasswordPage from "./ResetPasswordPage";
import AccountPage from "./AccountPage";
import { Grid, CssBaseline, Paper, Box } from '@material-ui/core'
import { config } from './config'
import Brand from './Brand'
import CopyRight from './CopyRight';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'


const theme = createMuiTheme({
	typography: {
		fontFamily: ['"Roboto"', 'sans-serif'].join(',')
	},	
})

export default function App() {
	const [isAuthenticated, setIsAuthenticated] = React.useState(false)
	const [authenticatedUsername, setAuthenticatedUsername] = React.useState('')

	return (
		<ThemeProvider theme={theme}>
		<Grid container component="main">
			<CssBaseline />
			<Grid item xs={false} sm={4} md={8}>
				<div style={{
					height: '100vh',
					backgroundImage: `url(${!isAuthenticated ? config.brand.imgSrc : config.account.imgSrc})`,
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
					backgroundPosition: 'center',
					transition: 'background-image 0.3s ease-in-out'
				}}></div>
			</Grid>
			<Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
				<Router>
					<div className="App">
						<Box
							sx={{
								my: 8,
								mx: 4,
								display: 'flex',
								flexDirection: 'column',
								alignItems: 'center',
							}}
						>
							<Brand className="page-title"/>
							<Switch>
								<Route path="/signup">
									<SignUpPage />
								</Route>
								<Route path="/account">
									<AccountPage
										isAuthenticated={isAuthenticated}
										setIsAuthenticated={setIsAuthenticated}
										authenticatedUsername={authenticatedUsername}
									/>
								</Route>
								<Route path="/resetpassword">
									<ResetPasswordPage />
								</Route>
								<Route path="/">
									<LoginForm
										isAuthenticated={isAuthenticated}
										setIsAuthenticated={setIsAuthenticated}
										setAuthenticatedUsername={setAuthenticatedUsername}
									/>
								</Route>
							</Switch>
							<div className="page-footer page-footer-width page-footer-visibility">
								<CopyRight />
							</div>
						</Box>
					</div>
				</Router>
			</Grid>
		</Grid>
		</ThemeProvider>
	);
}
